import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import "../Material/Material.css";
import { errorsMessage } from "../../constant";
import { Spinner } from "react-bootstrap";
import { deliveryChallanListApi } from "../commonComponents/storeApi";
import ChallanListData from "./ChallanListData";
import BasicPagination from "../commonComponents/Pagination";
import { debounce } from "lodash";
import SimpleListMenu from "../commonComponents/DropDownMenu";

function DeliveryChallanList() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [loader, setLoader] = useState(true);
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");

  function deliveryChallanList(term, page) {
    setLoader(true);
    deliveryChallanListApi({ user_id, page })
      .then((res) => {
        setListData(res?.data?.response?.listDeliveryChallan?.data);
        setPageCount(res?.data?.response?.listDeliveryChallan?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  function handlePaginateChange(event, value) {
    setPage(value);
  }

  const debouncedFetchDeliveryChallanList = useCallback(
    debounce((term, page) => {
      deliveryChallanList(term, page);
    }, 600),
    [user_id]
  );

  useEffect(() => {
    debouncedFetchDeliveryChallanList("", page);
  }, [debouncedFetchDeliveryChallanList, page]);

  function handlePaginateChange(event, value) {
    setPage(value);
  }

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="card mt-4">
            <div className="card-body tabsall">
              <div className="row">
                <div className="col-sm-4">
                  <div className="page-title-box">
                    <h2 className="page-title"> DELIVERY CHALLAN </h2>
                  </div>
                </div>
                <div className="col-md-8 d-flex align-items-center justify-content-end">
                  <div className="d-flex" style={{ width: "54%" }}>
                    <div class="input-group search_matarial d-flex align-items-center">
                      {/* <Select
                        placeholder="Search By Title"
                        style={{ width: "100%" }}
                        className="form-select-control border-secondary"
                        name="id"
                        isSearchable={true}
                        options={unitList}
                        getOptionLabel={(unitList) => unitList["store_name"]}
                        getOptionValue={(storeUnitList) => storeUnitList["id"]}
                        value={selectedStore}
                        onChange={handleStoreChange}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: "lightgrey",
                            primary: "lightgrey",
                          },
                        })}
                      /> */}

                      {/* <button type="button" class="btn btn-primary btn-sm">
                        <i class="fas fa-search"></i>
                      </button> */}
                    </div>
                    <div className="">
                      <SimpleListMenu />
                    </div>
                  </div>
                </div>
                {loader ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "70vh" }}
                  >
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                  </div>
                ) : (
                  <ChallanListData detail={listData} />
                )}
                <div className="text-end mt-2">
                  <BasicPagination
                    handlePaginateChange={handlePaginateChange}
                    page={page}
                    pageCount={pageCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryChallanList;
