import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaEdit } from "react-icons/fa";
import ControlledRadioButtonsGroup from "./RadioGroup";
import styles from "./OffCanvasComponent.module.css";
import { Link } from "react-router-dom";

const UpdateLotsLogs = ({
  values,
  touched,
  errors,
  text,
  validationError,
  handleMltClick,
  spinner,
  setFieldTouched,
  dropdowns,
  handleMultDelete,
  show,
  setShow,
  setType,
  type,
  amendedLogs,
  setFieldValue,
}) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        className={`${styles.toggleButton} `}
        type="button"
        onClick={handleShow}
      >
        <button className="btn btn-primary btn-sm me-3 px-2 ">
          <Link
            className="text-white fs-6  "
          >
            Edit <FaEdit size="17" />
          </Link>
        </button >

      </button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ width: "1000px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Update {text}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ControlledRadioButtonsGroup type={type} setType={setType} />
          {values?.pd_poloaQuantityMutipleData?.map((val, i) => (
            <div className="row" key={i}>
              <div className="col-md-2 col-sm-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label fw-bold">
                    PO/LOA Quantity<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control border-secondary "
                    placeholder="Enter PO/LOA Quantity"
                    id="poLoaQty"
                    name={`pd_poloaQuantityMutipleData[${i}].poLoaQty`}
                    value={values.pd_poloaQuantityMutipleData[i].poLoaQty}
                    onChange={(e) => {
                      const newValues = [...values.pd_poloaQuantityMutipleData];
                      newValues[i].poLoaQty = e.target.value;
                      setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                    }}
                    onBlur={(e) => {
                      setFieldTouched(
                        `pd_poloaQuantityMutipleData[${i}].poLoaQty`,
                        true
                      );
                    }}
                  />
                  <p className="text-danger m-0">
                    {touched.pd_poloaQuantityMutipleData &&
                      touched.pd_poloaQuantityMutipleData[i]?.poLoaQty &&
                      errors?.pd_poloaQuantityMutipleData &&
                      errors?.pd_poloaQuantityMutipleData[i]?.poLoaQty
                      ? errors?.pd_poloaQuantityMutipleData[i]?.poLoaQty
                      : Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${i}.poLoaQty`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                  </p>
                </div>
              </div>

              <div className="col-md-2 col-sm-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label fw-bold">
                    UOM<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control border-secondary "
                    id="unit"
                    value={values.pd_poloaQuantityMutipleData[i].unit}
                    onChange={(e) => {
                      const newValues = [...values.pd_poloaQuantityMutipleData];
                      newValues[i].unit = e.target.value;
                      setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                    }}
                    onBlur={(e) => {
                      setFieldTouched(
                        `pd_poloaQuantityMutipleData[${i}].unit`,
                        true
                      );
                    }}
                  >
                    <option value="">Select</option>
                    {dropdowns?.unitList?.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <p className="text-danger m-0">
                    {touched.pd_poloaQuantityMutipleData &&
                      touched.pd_poloaQuantityMutipleData[i]?.unit &&
                      errors?.pd_poloaQuantityMutipleData &&
                      errors?.pd_poloaQuantityMutipleData[i]?.unit
                      ? errors?.pd_poloaQuantityMutipleData[i]?.unit
                      : Object.entries(validationError)
                        ?.filter(([key, value]) => key.includes(`${i}.unit`))
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                  </p>
                </div>
              </div>

              <div className="col-md-2 col-sm-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label fw-bold">
                    Start Date<span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control border-secondary "
                    id="startDate"
                    // name={`pd_poloaQuantityMutipleData[${i}].startDate`}
                    value={values.pd_poloaQuantityMutipleData[i].startDate}
                    onChange={(e) => {
                      const newValues = [...values.pd_poloaQuantityMutipleData];
                      newValues[i].startDate = e.target.value;
                      setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                    }}
                    onBlur={(e) => {
                      setFieldTouched(
                        `pd_poloaQuantityMutipleData[${i}].startDate`,
                        true
                      );
                    }}
                  />

                  <p className="text-danger m-0">
                    {touched.pd_poloaQuantityMutipleData &&
                      touched.pd_poloaQuantityMutipleData[i]?.startDate &&
                      errors?.pd_poloaQuantityMutipleData &&
                      errors?.pd_poloaQuantityMutipleData[i]?.startDate
                      ? errors?.pd_poloaQuantityMutipleData[i]?.startDate
                      : Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${i}.startDate`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                  </p>
                </div>
              </div>

              <div className="col-md-2 col-sm-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label fw-bold">
                    Completion Date<span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control border-secondary "
                    id="completionDate"
                    // name={`pd_poloaQuantityMutipleData[${i}].completionDate`}
                    value={values.pd_poloaQuantityMutipleData[i].completionDate}
                    onChange={(e) => {
                      const newValues = [...values.pd_poloaQuantityMutipleData];
                      newValues[i].completionDate = e.target.value;
                      setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                    }}
                  />
                  <p className="text-danger m-0">
                    {touched.pd_poloaQuantityMutipleData &&
                      touched.pd_poloaQuantityMutipleData[i]?.completionDate &&
                      errors?.pd_poloaQuantityMutipleData &&
                      errors?.pd_poloaQuantityMutipleData[i]?.completionDate
                      ? errors?.pd_poloaQuantityMutipleData[i]?.completionDate
                      : Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${i}.completionDate`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                  </p>
                </div>
              </div>

              <div className="col-md-2 col-sm-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label fw-bold">
                    DP Extension
                  </label>
                  <input
                    type="date"
                    className="form-control border-secondary "
                    id="dpExtension"
                    // name={`pd_poloaQuantityMutipleData[${i}].completionDate`}
                    placeholder="Enter DP Extention"
                    value={values.pd_poloaQuantityMutipleData[i].dpExtension}
                    onChange={(e) => {
                      const newValues = [...values.pd_poloaQuantityMutipleData];
                      newValues[i].dpExtension = e.target.value;
                      setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-2 col-sm-6">
                <label className="form-label ">Lots (+/-)</label>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary  align-items-center me-2 fw-bold"
                    onClick={handleMltClick}
                  >
                    +
                  </button>
                  {!values.pd_poloaQuantityMutipleData[i].id ? (
                    <button
                      type="button"
                      className="btn btn-de-danger border-danger fw-bold"
                      onClick={() => handleMultDelete(i)}
                      hidden={i === 0}
                    >
                      -
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
          {spinner ? (
            <div className="">
              <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
              <p>Wait a moment....</p>
            </div>
          ) : (
            <div
              className="mt-2 text-end d-flex align-items-end justify-content-end"
            >
              <Button
                variant="danger"
                onClick={() => amendedLogs(type)}
              >
                Update
              </Button>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default UpdateLotsLogs;
