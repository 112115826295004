import axios from "axios";

const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
const API = axios.create({
  baseURL: apiurl,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile"))?.response?.token
    }`;
  }
  return req;
});

export const addGRN = (data) => API.post("/addpurchaseordergrn", data);
export const getGrnListing = (data) => API.post("/getGrnListing", data);
export const updateGrn = (data) => API.post("/updatePurchaseOrderGrn", data);
export const addDeliveryChallanApi = (data) =>
  API.post("/add-delivery-challan", data);
export const deliveryChallanListApi = (data) =>
  API.post("/list-delivery-challan", data);
export const UpdateDeliveryChallanApi = (data) =>
  API.post("/update-delivery-challan", data);
export const getInventoryListingApi = (data) =>
  API.post("/listinventoryitems", data);
export const getInventoryLogsListingApi = (data) =>
  API.post("/listinventorylogs", data);
export const getRequsitionListApi = (data) =>
  API.post("/listrequisitionform", data);
export const getDeliveryChallanPdf = (data) =>
  API.post("/generate-delivery-challan-pdf", data);
